<template>
  <div class="flex flex-col w-full">
    <label v-if="label" class="font-roboto-normal text-sm leading-5 text-cs-medium-grey dark:text-white mb-2">{{
      label
    }}</label>
    <VField v-slot="{field, meta, errors, resetField}" :name="name" :value="value">
      <div v-if="type === 'password'" class="relative inputGroup">
        <input
          :class="inputClasses"
          :disabled="disabled"
          :placeholder="placeholder"
          type="password"
          v-bind="field"
          @input="onKeyUp"
          @keyup.enter="onKeyEnter"
        />
        <div class="cursor-pointer absolute top-3 right-2" @click="togglePasswordVisibility">
          <IconEye :class="showPassword ? 'hidden' : 'block'" class="[&>path]:stroke-cs-light-blue w-[22px] h-5" />
          <IconEyeSlash :class="!showPassword ? 'hidden' : 'block'" />
        </div>
      </div>
      <div v-if="type === 'search'" class="relative">
        <div class="absolute top-[10px] left-[15px] cursor-pointer" @click="onKeyEnter">
          <IconMagnifyingGlass class="dark:[&>path]:stroke-cs-light-blue size-5" />
        </div>
        <input
          :class="inputClasses + ' pl-10'"
          :disabled="disabled"
          :placeholder="placeholder"
          type="text"
          v-bind="field"
          @input="onKeyUp"
          @keyup.enter="onKeyEnter"
        />
        <!--<IconRemoveSearch
          class="cursor-pointer absolute top-[10px] right-2 [&>path]:fill-cs-light-blue"
          @click="triggerReset(resetField)"
        />-->
      </div>
      <input
        v-if="!type || type === 'text' || type === 'number' || type === 'email'"
        :class="inputClasses"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        v-bind="field"
        @input="onKeyUp"
        @keyup.enter="onKeyEnter"
      />
      <VErrorMessage :name="name" as="div" class="text-cs-red text-sm mt-2.5" />
    </VField>
  </div>
</template>

<script lang="ts" setup>
import IconEye from '~/assets/icons/eye.svg'
import IconEyeSlash from '~/assets/icons/eye-slash.svg'
import IconMagnifyingGlass from '~/assets/icons/magnifying-glass.svg'

interface InputProps {
  placeholder?: string
  type?: 'password' | 'search' | 'text' | 'number' | 'email'
  label?: string
  name: string
  value?: string
  disabled?: boolean
  onKeyUpHandler?: Function
  onKeyEnterHandler?: Function
  hasBorder?: boolean
}

const props = withDefaults(defineProps<InputProps>(), {
  placeholder: 'Search',
  type: 'text',
  disabled: false,
  value: '',
  hasBorder: true
})

const showPassword = ref<boolean>(false)

const emit = defineEmits(['fieldReset'])

// Create default input classes
let inputClasses =
  ' rounded-md px-[22px] py-2 font-roboto-normal text-sm leading-6 text-cs-dark-color ' +
  ' dark:text-white w-full dark:placeholder-cs-medium-grey'

if (props.hasBorder) {
  inputClasses += ' border border-cs-light-grey  dark:bg-black dark:border-cs-medium-grey '
} else {
  inputClasses += ' bg-transparent focus:outline-0 '
}

const togglePasswordVisibility = (event: MouseEvent) => {
  const parentDiv = (event.target as HTMLElement).closest('.inputGroup')
  const element = parentDiv ? (parentDiv.children[0] as HTMLInputElement) : null

  if (element) {
    if (element.type === 'password') {
      element.type = 'text'
      showPassword.value = true
    } else {
      element.type = 'password'
      showPassword.value = false
    }
  }
}

const onKeyUp = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (target && props.onKeyUpHandler) {
    props.onKeyUpHandler(target.value)
  }
}

const onKeyEnter = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (target && props.onKeyEnterHandler) {
    props.onKeyEnterHandler(target.value)
  }
}

const triggerReset = (resetField: Function) => {
  // VField
  resetField()
  // Emit this event to parent component
  emit('fieldReset')
}
</script>
